@use '~/styles/modules' as *;

.column {
  @include flexContainer(column, center);

  .link {
    margin-top: 32px;
  }

  .title {
    color: var(--text-color);
    margin: 4rem auto;
    text-align: center;
  }

  .collectionCardGrid {
    width: 100%;
    @include masonryLayout();
    @include masonryHideFourth();
  }

  .nftCardGrid {
    width: 100%;
    @include masonryLayout();
  }

  .upcoming {
    @include flexContainer(column, center, center);
    flex-wrap: wrap;
    width: 100%;
    @include gridWrap();
    @include medium-screens() {
      @include flexContainer(row, space-around, center);
    }
  }
}
